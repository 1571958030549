import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"
import CurrentProject from "../components/CurrentProject"

const current_projects = [
    {
        title: "4 DOF Scara Arm",
        description: "Designed and developed a 4 Degree of Freedom SCARA Arm. Mostly cause I've wanted to, but also in the context of automated loading/unloading of a production machine. Specifically currently working a webapp for controlling the arm similar in structure (React Django) to the SoldWorks WebApp.",
        link: "/scara",
        ref_links: [
            {
                link:"/solidworks",
                name:"SolidWorks Webapp",
            },
            {
                link:"/scara_webapp",
                name:"SCARA Webapp",
            }
        ],
    },
    {
        title: "Frontrunning Sports Betting with Twitter",
        description: "(Discontinued) Sports Betting moves odds very fast, but sometimes Twitter moves just a little faster. For some key events (i.e. player injury) that could impact the outcome of the game, people at the game tweet a couple seconds before the bookies in Vegas can move the line. This presents an opportunity to place a bet at the old odds and get a slightly biased bet. Repeat enough times, and you could (statistically) expect a positive return. We are still in the (back) testing phase but preliminary results are promising.",
        link: null,
        ref_links: [

        ],
    },
    {
        title: "C# and the SolidWorks API",
        description: "The SolidWorks API is very thorough. Pretty much everything you can do in SolidWorks, you can also do in the SolidWorks API. I have begun experimenting with this process to do design checking and automation for the stuff I work on with SwIRL. For example, taking an assembly of laser cut parts, validating the geometry supports laser cutting (its all flat parts), laying them out on a sheet, optimizing the placement using polygon packing algorithm(s), and generating Ruida `.rd` files for the laser cutter. This has given me an opportunity to work with C# and also the Windows COM (Component Object Model).",
        link: null,
        ref_links: [
            {
                link:"/swirl",
                name: "SwIRL",
            },
            {
                link: "https://help.solidworks.com/2021/English/SolidWorks/sldworks/c_solidworks_api.htm",
                name: "SolidWorks API",
            }
        ],
    },
    {
        title: "EDGAR, NLP, and the SEC",
        description: "(Discontinued) There is a-lot of data available for publicly traded companies, but perhaps the best source is directly from the company themselves. Enter EDGAR (Electronic Data Gathering, Analysis, and Retrieval system), the SEC's automated solution for collecting and distributing these documents. Initially this started as a simple python script similar to the pip package `sec_api` but has grown with the desire to run efficient full-text search on the contents of these filings. The next steps are to integrate the high performance parallel text processing concepts which I worked on in CSCE 313 as well as to experiment with some natural language processing. The index of all 10K annual reports from January 2018 until March 2022 is about 450 GB.",
        link: null,
        ref_links: [
            {
                link: "/wikipedia",
                name: "CSCE 313 Wikipedia",
            },
            {
                link: "https://pypi.org/project/sec-api/",
                name: "PIP SEC API",
            },
        ],
    },
    {
        title: "Website Improvements, Testing, and CRM",
        description: "As much as I try and avoid it, I keep being drawn back into WebApps stuff that I don't fully understand (see Sumobots, CS462). To remedy this, I've been improving my personal website (i.e. this website). I've moved to a React & Gatsby stack to help automate most of the HTML generation (previously it was a mess of python scripts), but there is much more to do. Principal among which is to integrate some testing framework and a CRM package to make the editing experience more enjoyable.",
        link: "/website",
        ref_links: [
            {
                link:"/sumobots",
                name: "Sumobots",
            },
            {
                link: "/cs462",
                name: "CSCE 462",
            },
        ],
    }
];

const CurrentPage = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>Current Projects:</h1>
            {CurrentProject()}
            {current_projects.map(proj => (
                <div>
                    <h4>{proj.title}</h4>
                    <p>{proj.description}</p>
                    {((proj.link != null) ? <a href={proj.link}>Read More</a> : "")}
                    {((proj.ref_links.length !== 0) ? (
                        <div>
                            <p>Referenced Links:</p>
                            <ul>
                                {proj.ref_links.map(link => (
                                    <li><a href={link.link}>{link.name}</a></li>
                                ))}
                            </ul>
                        </div>
                    ) : "")}
                </div>
            ))}
            {FooterBar()}
        </div>
    )
}

export default CurrentPage